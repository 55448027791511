import React from "react";
import CommonHeader from "./commonheader/CommonHeader";
import GoToTop from "./GoToTop";
import Footer from "./footer/Footer";

function ComingSoon() {
  return (
    <div>
      <CommonHeader />

      <div
        className="main-heading1"
        style={{
          height: "80vh",
        }}
      >
        <h1>COMING SOON</h1>
        <h2 style={{ textAlign: "center" }}>
          {" "}
          "We are preparing something amazing and exciting for you.
          <br /> We also have a special surprise for new users."
        </h2>
      </div>
      <GoToTop />
      <Footer />
    </div>
  );
}

export default ComingSoon;
