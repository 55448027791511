import "./styles/app.css";
import Home from "./components/homepage/Home";
import React from "react";
import Footer from "../src/components/footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ParticleAnimation from "../src/components/ParticleAnimation";
import ScrollToTopButton from "./components/button/ScrollToTopButton";
import CompanyInfo from "./components/companypage/CompanyInfo";
import HowItWorks from "./components/howitworks/HowItWorks";
import Advertise from "./components/advertisepage/Advertise";
import HelpFaq from "./components/helpFAQpage/HelpFaq";
import Contact from "./components/contactpage/Contact";
import ComponentState from "./components/context/ComponentState";
import TermsOfService from "./components/termsconditon/TermsOfService";
import PrivacyPolicy from "./components/termsconditon/PrivacyPolicy";
import Advertisewithus from "./components/advertisepage/Advertisewithus";
import ComingSoon from "./components/ComingSoon";
import "./styles/button/usablebutton.css";
import Cookie from "./components/Cookie";
import connecttofirebase from "./config/connecttofirebase";
import Login from "./components/login/Login";
import ForgotPassword from "./components/email/ForgotPassword";

import { ToastContainer } from "react-toastify";
import GoToTop from "./components/GoToTop";
import UserAction from "./components/email/UserAction";

function App() {
  // if (
  //   (navigator.userAgent.indexOf("Opera") ||
  //     navigator.userAgent.indexOf("OPR")) !== -1
  // ) {
  //   alert("Opera");
  // } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
  //   alert("Chrome");
  // } else if (/Edge/.test(navigator.userAgent) !== -1) {
  //   alert("Edge");
  // } else if (navigator.userAgent.indexOf("Safari") !== -1) {
  //   alert("Safari");
  // } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
  //   alert("Firefox");
  // } else if (
  //   navigator.userAgent.indexOf("MSIE") !== -1 ||
  //   !!document.documentMode === true
  // ) {
  //   //IF IE > 10
  //   alert("IE");
  // } else {
  //   alert("unknown");
  // }
  connecttofirebase();
  return (
    <>
      <ComponentState>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
          </Routes>
          <Routes>
            <Route exact path="/companyinfo" element={<CompanyInfo />}></Route>
          </Routes>
          <Routes>
            <Route exact path="/howitworks" element={<HowItWorks />}></Route>
          </Routes>
          <Routes>
            <Route exact path="/advertise" element={<Advertise />}></Route>
          </Routes>
          <Routes>
            <Route exact path="/helpfaq" element={<HelpFaq />}></Route>
          </Routes>
          <Routes>
            <Route exact path="/contact" element={<Contact />}></Route>
          </Routes>
          {/* <Routes>
            <Route
              exact
              path="/termsofservices"
              element={<TermsOfService />}
            ></Route>
          </Routes> */}
          <Routes>
            <Route
              exact
              path="/privacypolicy"
              element={<PrivacyPolicy />}
            ></Route>
          </Routes>
          <Routes>
            <Route
              exact
              path="/advertisewithus"
              element={<Advertisewithus />}
            ></Route>
          </Routes>
          <Routes>
            <Route exact path="/comingsoon" element={<ComingSoon />}></Route>
          </Routes>
          <Routes>
            <Route exact path="/login" element={<Login />}></Route>
          </Routes>
          <Routes>
            <Route
              path="/useraction/__/auth/action"
              element={<UserAction />}
            ></Route>
          </Routes>

          <Routes>
            <Route
              exact
              path="/forgotpassword"
              element={<ForgotPassword />}
            ></Route>
          </Routes>
          <ToastContainer
            autoClose={3000}
            draggable={false}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            position="top-center"
            theme="light"
          />
          <ScrollToTopButton />
          <Cookie />

          <ParticleAnimation />
        </Router>
      </ComponentState>
    </>
  );
}

export default App;
