import React, { useContext, useEffect } from "react";
import WhatWeDo from "./WhatWeDo";
import WhereWeAre from "./WhereWeAre";
import CommonHeader from "../commonheader/CommonHeader";
import ComponentContext from "../context/ComponentContext";
import GoToTop from "../GoToTop";
import Footer from "../footer/Footer";

function CompanyInfo() {
  const { links, setLinks } = useContext(ComponentContext);
  useEffect(() => {
    setLinks({
      cominfo: false,
      howitworks: true,
      advertise: true,
      help: true,
      contact: true,
    });
  }, []);

  return (
    <div>
      <CommonHeader link="true" />
      <WhatWeDo />
      <WhereWeAre />
      <GoToTop />
      <Footer />
    </div>
  );
}

export default CompanyInfo;
