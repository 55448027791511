import React from "react";
import "../../styles/home/friend.css";
import currentlyActive from "../../icons/CurrentlyActive.svg";
import newSignUp from "../../icons/NewSignUp.svg";
import contestsParticipants from "../../icons/contestParticipants.svg";
import Counter from "../Counter";

function Friend() {
  return (
    <div className="friend-section">
      <div className="sub-heading">
        <h2>TOTAL APP USERS</h2>
        <h3>
          {" "}
          <Counter start="0" end="1000000" />{" "}
        </h3>
      </div>
      <div>
        <div className="friend-counter-section">
          <div className="counter-section-inner">
            <svg className="link-img" data-src={currentlyActive}></svg>

            <h2>
              Currently <br className="break" /> Active
            </h2>
            <h3>100000</h3>
          </div>
          <div className="counter-section-inner">
            <svg className="link-img" data-src={newSignUp}></svg>

            <h2>
              New
              <br className="break" /> SignUp
            </h2>
            <h3>100000</h3>
          </div>
          <div className="counter-section-inner">
            <svg className="link-img" data-src={contestsParticipants}></svg>

            <h2>
              All <br className="break" />
              Contestants
            </h2>
            <h3>600000</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Friend;
