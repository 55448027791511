import React from "react";
import UsableButton from "../button/UsableButton";
import "../../styles/advertise/waysofadvertise.css";
import banner from "../../logo/Advertise/banner-max-min.png";
import campaign from "../../logo/Advertise/campaign-max-min.png";
import sponsored from "../../logo/Advertise/sponsored-max-min.png";
import paidtoclick from "../../logo/Advertise/paid-to-click-max-min.png";
import * as LottiePlayer from "@lottiefiles/lottie-player";

function Waysofadvertise() {
  return (
    <div>
      <div className="ways-of-advertise">
        <div className="ways-of-advertise-section">
          <div className="sub-heading">
            <h2>Way(s) Of Advertising</h2>
          </div>
          <section className="work">
            <div className="work-inner">
              <div className="work-inner-box work-box">
                <div className="ih-item square colored effect10 top_to_bottom">
                  <div className="square-inner">
                    <div className="img">
                      <img src={sponsored} alt="sponsored image" />
                    </div>
                    <div className="info">
                      <p>
                        You provide a URL, a promo code and a short message. We
                        announce the promotion and our users must view your URL
                        before redeeming the promo code for free Coins.
                        Everybody wins!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="title">
                  <h2>Sponsored Promotions</h2>
                </div>
              </div>
              <div className="work-inner-box work-box">
                <div className="ih-item square colored effect10 top_to_bottom">
                  <div className="square-inner">
                    <div className="img">
                      <img src={banner} alt="banner image" />
                    </div>
                    <div className="info">
                      <p>
                        High visibility banner placements for maximum results.
                        Competitive CPM's and a highly engaged audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="title">
                  <h2>Banner Ads</h2>
                </div>
              </div>
              <div className="work-inner-box work-box">
                <div className="ih-item square colored effect10 top_to_bottom">
                  <div className="square-inner">
                    <div className="img">
                      <img src={paidtoclick} alt="paidtoclick image" />
                    </div>
                    <div className="info">
                      <p>
                        Users must visit your website, video or URL for a
                        certain number of seconds before being awarded Coins.
                        Cheap, simple and effective advertising.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="title">
                  <h2>Paid To Click</h2>
                </div>
              </div>
              <div className="work-inner-box work-box">
                <div className="ih-item square colored effect10 top_to_bottom">
                  <div className="square-inner">
                    <div className="img">
                      <img src={campaign} alt="campaign image" />
                    </div>
                    <div className="info">
                      <p>
                        Only pay for users who sign up or complete a specific
                        action on your website, app, or other platform.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="title">
                  <h2>CPA & CPE Campaigns</h2>
                </div>
              </div>
            </div>
            {/* <div>
              <div className="banner-card">
                <h2>SPECIAL OFFER</h2>
                <h3>(IN-APP ADS) </h3>
                <p>
                  Video, Banner Anything <br /> Cost Per Year: <b>10000</b>
                  <br /> Reniew Cost Next Year: <b> 100000</b> <br />
                  Confirm Daily Views: <b>10000</b> <br /> or <br /> Confirm
                  Daily Clicks: <b>10000</b>
                  <br />
                  terms and services applicable.
                </p>
                <div className="btn">
                  <UsableButton
                    color="light"
                    hreflink="/advertisewithus"
                    button={true}
                    text="More Details"
                  />
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>
    </div>
  );
}

export default Waysofadvertise;
