import "../../styles/button/usablebutton.css";
import React from "react";
import { Link } from "react-router-dom";

// download button usable component

function UsableButton({ color, text, icon, link, button, hreflink, padding }) {
  return (
    <>
      {link && (
        <a
          className={`downloadlink ${color}`}
          style={{
            padding: `${padding}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          href={`${hreflink}`}
          target="_blank"
          rel="noreferrer"
        >
          {icon && text !== "" && <i className={`${icon}`}>&nbsp; &nbsp;</i>}
          {icon && text === "" && <i className={`${icon}`}></i>}
          {text}
        </a>
      )}
      {button && (
        <Link
          className={`downloadlink ${color}`}
          style={{
            padding: `${padding}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          to={`${hreflink}`}
        >
          {icon && text !== "" && <i className={`${icon}`}>&nbsp; &nbsp;</i>}
          {icon && text === "" && <i className={`${icon}`}></i>}
          {text}
        </Link>
      )}
    </>
  );
}

UsableButton.defaultProps = {
  color: "dark",
  button: false,
  link: false,
  padding: "1rem",
};

export default UsableButton;
