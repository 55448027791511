import React, { useContext } from "react";
import "../../styles/home/rupee.css";
import moneyClaimed from "../../icons/MoneyClaimed.svg";
import totalUser from "../../icons/TotalUsers.svg";
import contests from "../../icons/ContestsHeld.svg";
import { Link } from "react-router-dom";
import Counter from "../Counter";

function Rupee() {
  return (
    <div className="rupee-section">
      <div className="sub-heading">
        <h2>We've Paid Our Members Over</h2>
        <h3>
          <Counter start="0" end="1000000" icon={true} />{" "}
        </h3>
      </div>
      <div>
        <div className="rupee-counter-section">
          <div className="counter-section-inner">
            <svg data-src={totalUser} alt="rupee-section-img"></svg>
            <h2>
              Total
              <br className="break" /> Users
            </h2>
            <h3>100000</h3>
          </div>
          <div className="counter-section-inner">
            <svg data-src={moneyClaimed} alt="rupee-section-img"></svg>
            <h2>
              Money
              <br className="break" /> Claimed
            </h2>
            <h3>₹100000</h3>
          </div>
          <div className="counter-section-inner">
            <svg data-src={contests} alt="rupee-section-img"></svg>
            <h2>
              Contests <br className="break" /> Held
            </h2>
            <h3>100000</h3>
          </div>
        </div>
      </div>
      <div className="bonus">
        <Link to="/login" className="bonus-inner">
          Instant Cash Bonus <br />
          ₹25
        </Link>
      </div>
    </div>
  );
}

export default Rupee;
