import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import oldArray from "./data";
import UsableButton from "../../components/button/UsableButton";

import "../../styles/helpfaq/faq.css";

function FAQ() {
  const [searchvalue, setSearchValue] = useState("");
  const onChange = (e) => {
    setSearchValue(e.target.value);
  };
  let questionAnsArray = [];
  // search function

  if (searchvalue) {
    let pattern = new RegExp(searchvalue, "gi");
    questionAnsArray = oldArray.filter((item) => {
      return pattern.test(item.question);
    });
  } else {
    questionAnsArray = oldArray;
  }

  const onClick = (e) => {
    e.target.closest(".questions-inner").classList.toggle("active");
  };

  return (
    <div>
      <div className="faq-section">
        <div className="faq-section-inner">
          <div className="sub-heading">
            <h2>FAQ</h2>
            <h3>How can we help you today?</h3>
            <div className="input-group">
              <i className="fas fa-search"></i>
              <input
                className="input-control"
                type="search"
                name="search"
                id="search"
                placeholder="Type here to search"
                value={searchvalue}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="main-faq">
            <div className="video">
              <lottie-player
                src="https://assets2.lottiefiles.com/packages/lf20_ssIwdK.json"
                mode="normal"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </div>
            <div className="main-faq-inner">
              <div className="questions">
                {questionAnsArray.length === 0 ? (
                  <p>Nothing to show!</p>
                ) : (
                  questionAnsArray.map((item) => (
                    <div className="questions-inner" key={item.question}>
                      <div className="question" onClick={onClick}>
                        {parse(item.question)}
                        <div className="icon">
                          <i className="fas fa-chevron-down"></i>
                        </div>
                      </div>
                      <div className="answer">
                        {item.answer.includes("<Link") ? (
                          <p>
                            No. For now, we're not charging transaction fees but
                            we will might change our &nbsp;
                            <Link to="/termsofservices" className="link">
                              terms and services.
                            </Link>
                          </p>
                        ) : (
                          <p>{parse(item.answer)}</p>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="details">
            <p>Still can't find what you're looking for?</p>
            <div className="btn">
              <UsableButton
                text="Email Us"
                button={true}
                hreflink="/contact"
                color="light"
              />
            </div>
            <p>
              Thank you for your patience as our customer service team is
              experiencing increased contact volume and has a delay in phone and
              email response times. Check out our faqs at any time. We look
              forward to serving you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
