import React from "react";
import ContactForm from "./ContactForm";
import "../../styles/contact/contactus.css";
import { Link } from "react-router-dom";

function Contactus() {
  return (
    <div>
      <div className="contact-with-us">
        <div className="sub-heading">
          <h2>CONTACT</h2>
        </div>
        <div className="contact-with-us-inner">
          <div className="inner">
            <p>
              While we're good with smoke signals, there are simpler ways for us
              to get in touch and answer your questions.
            </p>
            <h3>
              With <b>RuPick</b> you can:
            </h3>
            <p>
              Get help from our award-winning team. Get help with a technical
              and security issue. Get help to launch your campaign and to ensure
              your{" "}
              <Link className="link" to="/advertise">
                campaign
              </Link>{" "}
              is a success.
            </p>

            <h3>General communication</h3>
            <p>
              For general queries, including partnership opportunities, please
              email{" "}
              <a className="link" href="mailto:info@rupick.app">
                info@rupick.app
              </a>
            </p>
            <h3>Technical or account support</h3>
            <p>
              We’re here to help! If you have technical issues,{" "}
              <Link className="link" to="/helpfaq">
                contact support.
              </Link>{" "}
            </p>
          </div>
          <div className="video">
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_twijbubv.json"
              mode="bounce"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
}

export default Contactus;
