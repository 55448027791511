// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getPerformance } from "firebase/performance"; // TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMPQajZOLgip_V4D5dKQAV3ARR7v_3EvM",
  // authDomain: "rupick-1dee4.firebaseapp.com",
  authDomain: "rupick.app",
  projectId: "rupick-1dee4",
  storageBucket: "rupick-1dee4.appspot.com",
  messagingSenderId: "199147810733",
  appId: "1:199147810733:web:8031d500baf3da9f4afb24",
};

// Initialize Firebase

const connecttofirebase = () => {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  getPerformance(app);
};

export default connecttofirebase;
