import React from "react";

import "../../styles/map/map.css";

function Map() {
  return (
    <div className="map">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29941.297913954306!2d72.8492187148847!3d19.25608360672623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b0e840fe4d8b%3A0x411c43272d64a075!2sGanesh%20Nagar!5e0!3m2!1sen!2sin!4v1640153542272!5m2!1sen!2sin"
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default Map;
