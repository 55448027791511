import React from "react";
import "../../styles/howitworks/features.css";
// import "../../styles/ihover.css";
import communitylogo from "../../logo/howitworks/community-max-min.png";
import expertsupportlogo from "../../logo/howitworks/expert-support-max-min.png";
import loyaltylogo from "../../logo/howitworks/loyalty-bonus-max-min.png";
import multiplylogo from "../../logo/howitworks/multiply-coin-max-min.png";
import rewardlogo from "../../logo/howitworks/reward-max-min.png";
import withdrawlogo from "../../logo/howitworks/withdraw-deposit-max-min.png";

function Features() {
  return (
    <div className="feature-section">
      <div className="sub-heading">
        <h2>Features</h2>
      </div>
      <div>
        <section className="feature">
          <div className="feature-inner">
            <div className="feature-inner-box feature-box">
              <div className="ih-item square colored effect10 top_to_bottom">
                <div className="square-inner">
                  <div className="img">
                    <img src={rewardlogo} alt="rewardlogo image" />
                  </div>
                  <div className="info">
                    <p>
                      Earn reward points by completing tasks and boosting your
                      level. Cash in reward points for real prizes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Reward Points</h2>
              </div>
            </div>
            <div className="feature-inner-box feature-box">
              <div className="ih-item square colored effect10 top_to_bottom">
                <div className="square-inner">
                  <div className="img">
                    <img src={multiplylogo} alt="multiplylogo image" />
                  </div>
                  <div className="info">
                    <p>
                      Multiply your Coins by participating into different
                      contests.
                    </p>
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Multiply Your Coins</h2>
              </div>
            </div>
            <div className="feature-inner-box feature-box">
              <div className="ih-item square colored effect10 top_to_bottom">
                <div className="square-inner">
                  <div className="img">
                    <img src={withdrawlogo} alt="withdrawlogo image" />
                  </div>
                  <div className="info">
                    <p>
                      Fast Deposit and Withdraw your Coins any time to app
                      wallet or bank account respectively.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Withdrawal Coins</h2>
              </div>
            </div>
            <div className="feature-inner-box feature-box">
              <div className="ih-item square colored effect10 top_to_bottom">
                <div className="square-inner">
                  <div className="img">
                    <img src={loyaltylogo} alt="loyaltylogo image" />
                  </div>
                  <div className="info">
                    <p>
                      Login each day to boost your loyalty bonus all the way to
                      100%.
                    </p>
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Loyalty Support</h2>
              </div>
            </div>
            <div className="feature-inner-box feature-box">
              <div className="ih-item square colored effect10 top_to_bottom">
                <div className="square-inner">
                  <div className="img">
                    <img src={communitylogo} alt="communitylogo image" />
                  </div>
                  <div className="info">
                    <p>
                      Chat & share your progress with other like-minded RuPick
                      users.
                    </p>
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Community</h2>
              </div>
            </div>
            <div className="feature-inner-box feature-box">
              <div className="ih-item square colored effect10 top_to_bottom">
                <div className="square-inner">
                  <div className="img">
                    <img
                      src={expertsupportlogo}
                      alt="expertsupportlogo image"
                    />
                  </div>
                  <div className="info">
                    <p>
                      Our support team is the best in the business and is always
                      available to help.
                    </p>
                  </div>
                </div>
              </div>
              <div className="title">
                <h2>Expert Support</h2>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Features;
