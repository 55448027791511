const questionAnsArray = [
  {
    question: `<p>What is <b>RuPick?</b></p>`,
    answer: `<b>RuPick</b> is the "First" ever INDIA's social media
      rewards platform. Users earn free rewards or credit points
      by interacting and completing tasks. Day, Date-Month, Year
      at Time.`,
  },
  {
    question: `<p>How much Contests are there in the app?</p>`,
    answer: `There are two type of contests available on platorm.
      <br />
      1. Snap Contests <br />
      2. Meme Contests`,
  },
  {
    question: `<p>What is CP?</p>`,
    answer: `CP is what we call the credit points you earn when using
    our contests, offerwall and tasks/requirements. The value
    of a Credit Points is tied to the INR when you cash out.
  `,
  },
  {
    question: `<p>How to play Contests?</p>`,
    answer: `	Please refer how it works in contest section by downloading and <b>RuPick</b> App.							
    `
    ,
  },
  {
    question: `<p> How can I change my Email-Id ?</p> `,
    answer: `Go to the app profile page click on the link that says
    edit profile, you'll be able to edit all the details
    you've already entered while signup.`,
  },
  {
    question: `<p>Do you have a referral program?</p>`,
    answer: `Currently we don't have referal program but we're planning
    to introduce in next update.`,
  },
  {
    question: `<p>How does <b>RuPick</b> make money?</p>`,
    answer: `We make money from the ads we show and we get a cut from
    the contests we host.`,
  },
  {
    question: `<p>How can I refer a friend?</p>`,
    answer: `Go to the app profile page and click on the link that says
    Share Profile.`,
  },
  {
    question: `<p>What is Offerwall?</p>`,
    answer: `Offerwall is service that pay users for doing some kind of
    task. Those tasks include watching a video and the ads
    that come with them; visiting web pages; filling out
    surveys; installing apps; signing up for services, etc.`,
  },
  {
    question: `<p>How can I increase my credit points?</p>`,
    answer: `Participate in multiple contests with higher price pool.
    `,
  },
  {
    question: `<p>My Payment Is Pending or under review.</p>`,
    answer: `All payments are marked Pending while they await
    processing. If a payment goes to Review (see below) and is
    cleared, it will go back to Pending until it is sent. You
    can see our normal processing times on the withdrawal form
    you used to make your withdrawal, or in this article:
    &nbsp;
    <a href="/helpfaq#payment" className="link">
      How long does it take to get my payment?
    </a>
    &nbsp; Under Review: Occasionally our system will
    automatically flag payments for manual review. This can
    happen for a number of reasons. If you have been an honest
    user and have not been abusing the Cointiply website, you
    have nothing to worry about and your payment will be
    reviewed and released, usually in 24-72 hours. Sometimes
    this process can take longer if we are experiencing a high
    payment volume.`,
  },
  {
    question: `<p>When do I get Paid?</p>`,
    answer: `You can withdraw directly to your bank account when you
    have at least 100 credit points.`,
  },
  {
    question: `<p>I have lost my password or want to reset my password.</p>`,
    answer: `Go to the app login page and click on the link that says
  Forgot Password below login box.`,
  },
  {
    question: `<p>How do I withdraw?</p>`,
    answer: `To initiate a withdrawal, go to your withdrawal page
    (Profile &gt; Manage CP &gt; Withdrawal). Choose the kind
    of withdrawal you wish to make. Enter the withdrawal
    amount and hit request. Our withdrawal limits are: Min.:
    100 CP = 100 INR. Mx.: 500CP /per DAY (Not Applicable in
    Special Contests) You will not be able to start a
    withdrawal until you have the minimum number of CP's
    required to withdraw.`,
  },
  {
    question: `<p>How do I trigger my withdrawal?</p>`,
    answer: `Go to the "Profile" page, click on "Manage CP". You can
    view the status of it on the "Passbook" page.`,
  },
  {
    question: `<p>Do you charge transaction fees for withdrawals?</p>`,
    answer: `No. For now, we're not charging transaction fees but we will
    might change our &nbsp;
    <Link path="/termsofservices" className="link">
      terms and services.
    </Link>`,
  },
  {
    question: `<p>How much are CP's worth?</p>`,
    answer: `On <b>RuPick</b> you earn CP's. Those CP's are tied to the
    INR. More specifically, 1CP = 1.00 INR.`,
  },
  {
    question: `<p>I completed an offer and no Coins were credited, what can
    I do?</p>`,
    answer: `If you completed an offer you believe you should receive
    credit for, here is what you need to do: Wait 24 to 72
    hours. you will be credited within 24 - 72 hours. Double
    check you have met all of the offer requirements. Be
    patient and wait for a response. There are millions of
    other users, like you, completing offers and sometimes it
    takes a while before you hear back. Continue completing
    other offers in the meantime. Completing offers is a
    numbers game, and you will never get credit for 100%
    offers. Don't let that stop you! Reasons You May Not Get
    Credit: You did not fully complete the offers
    requirements. You used a VPN or proxy to access an offer
    or survey. You completed the offer too quickly compared to
    other users. The offer or survey may have experienced a
    technical issue which prevented your credit from being
    recorded. Note: Please keep in mind we can NOT see whether
    you have completed an offer or not and we have no insight
    into why the offer did not get credit. Here's some tips to
    make sure you get credited: Do not use a proxy or VPN -
    you will not get credit and your account may be disabled.
    Disable your Ad Blocker if you have one. Make sure to
    follow each offers instructions carefully. Wait for a
    while - sometimes credits can be delayed by hours or even
    days for bigger offers.`,
  },
  {
    question: `<p>How can I avoid my account being terminated?</p>`,
    answer: `<b>RuPick</b> does not tolerate fraud, bot activity or any
    artificial means of trying to "cheat" the system. Please
    stick to "Terms and Services" prohibited guidelines.`,
  },
  {
    question: `<p>My Payment Delayed or Held.</p>`,
    answer: `Occasionally our payment systems require maintenance,
    updates or simply need funds to be added (which can take
    time). We do our best to pay promptly but appreciate your
    patience when we have slight delays. If your payment
    status is "Held" this means we have detected unusual
    activity on your account and we are holding your payment
    until we can do an in depth manual review. If you have
    been an honest user, you have nothing to worry about it
    and we can straighten it out with you. Do not try to make
    up excuses for the unusual activity on your account - we
    have heard them all and will see right through them. The
    best thing you can do is be honest with us. We are people
    too and we will understand.`,
  },
  {
    question: `<p>Why can't I use VPN?</p>`,
    answer: `Our terms of service prohibit the use of VPNs, proxies, or
    other methods that obfuscate your IP address.`,
  },
  {
    question: `<p id="payment">How long does it take to get my payment?</p>`,
    answer: `information about processing times on the withdrawal page.
    We have included that information below for your
    convenience. If your payment is "Pending" or in "Review"
    then there is no need to panic. Your payment will be sent
    during our next payment cycle.`,
  },
];

export default questionAnsArray;
