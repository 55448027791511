import React, { useState } from "react";
import "../../styles/button/scrolltotopbutton.css";

const ScrollToTopButton = () => {
  const [opacity, setOpacity] = useState("0");
  const [visible, setVisible] = useState("hidden");
  const [transform, setTransform] = useState("translateY(-150px)");

  const toggleVisible = () => {
    const scrolled = window.scrollY;

    if (scrolled > 300) {
      setOpacity("1");
      setVisible("visible");
      setTransform("translateY(0)");
    } else {
      setOpacity("0");
      setVisible("hidden");
      setTransform("translateY(-150px)");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <button
      className="scrollbutton"
      onClick={scrollToTop}
      style={{
        opacity: `${opacity}`,
        visibility: `${visible}`,
        transform: `${transform}`,
      }}
    >
      <i className="fas fa-arrow-up"></i>
    </button>
  );
};

export default ScrollToTopButton;
