import React from "react";
import Particles from "react-tsparticles";
import "../styles/particle.css";
import rupeeLogo from "../logo/rupee_bg_logo.png";
import rupeeLogoBag from "../logo/rupee_bg_bag.png";

// particle animation component
function ParticleAnimation() {
  return (
    <>
      <section className="animation-section">
        <div class="leaf">
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
        </div>

        <div class="leaf leaf1">
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
        </div>

        <div class="leaf leaf2">
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>

          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>

          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogoBag} height="65px" width="65px" />
          </div>
          <div>
            <img alt="hey" src={rupeeLogo} height="45px" width="45px" />
          </div>
        </div>
      </section>
    </>
  );
}

export default ParticleAnimation;
