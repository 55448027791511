import React, { useEffect, useState, useContext } from "react";
import "../../components/commonheader/CommonHeader";
import CommonHeader from "../../components/commonheader/CommonHeader";
import * as Yup from "yup";
import { email, password } from "../../config/ValidationSchema";
import { Formik } from "formik";
import "../../styles/login/login.css";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import ComponentContext from "../context/ComponentContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFirestore,
  query,
  collection,
  getDocs,
  where,
} from "firebase/firestore";

const loginValidation = Yup.object().shape({
  email: email,
});

export default function ForgotPassword() {
  const { setLinks } = useContext(ComponentContext);

  useEffect(() => {
    setLinks({
      cominfo: true,
      howitworks: true,
      advertise: true,
      help: true,
      contact: true,
    });
  }, []);
  return (
    <div>
      <CommonHeader link={true} />
      <div className="login">
        <div className="wrapper">
          <h2>Forgot Password</h2>

          <div className="login-form">
            <Formik
              validationSchema={loginValidation}
              initialValues={{ email: "" }}
              onSubmit={async (values) => {
                const q1 = query(
                  collection(getFirestore(), "users"),
                  where("email", "==", values.email)
                );

                const querySnapshot1 = await getDocs(q1);
                if (querySnapshot1.size == 0) {
                  toast.error("User with this email doesn't exists.");
                } else {
                  sendPasswordResetEmail(getAuth(), values.email).then(
                    (res) => {
                      console.log("done");
                      toast.success("Password reset link has been sent.");
                    }
                  );
                }
              }}
            >
              {({ errors, touched, handleChange, handleSubmit, values }) => (
                <div className="main-form">
                  <div className="form-wrapper">
                    <input
                      type="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange("email")}
                      style={{
                        borderColor: touched.email
                          ? errors.email
                            ? "red"
                            : "green"
                          : "lightgray",
                      }}
                    />
                    {errors.email && touched.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="submitBtn"
                  >
                    Send Link
                  </button>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
