import React from "react";
import CookieConsent from "react-cookie-consent";
import "../styles/button/usablebutton.css";

function Cookie() {
  return (
    <CookieConsent
      disableStyles="true"
      buttonClasses="cookie-btn dark"
      buttonText="Accept"
      containerClasses="cookie-bar"
      contentClasses="text-capitalize"
    >
      <p>This website uses cookies to enhance the user experience.</p>
    </CookieConsent>
  );
}

export default Cookie;
