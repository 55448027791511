import React from "react";
import { Link } from "react-router-dom";
import "../../styles/termsandpolicy/privacy.css";

function Privacy() {
  return (
    <div>
      <div className="privacy-section">
        <div className="privacy-section-inner">
          <div className="sub-heading">
            <h2>PRIVACY POLICY</h2>
            <hr />
          </div>
          <div className="details">
            <b>Effective Date: January 12, 2022.</b>
            <br />
            <p>
              This Privacy Policy describes how RuPick Pvt. Ltd. (“RuPick”,
              “RuPick App,” “we,” “us,” or “our”). Protects your privacy when
              you use RuPick App (the "RuPick App Service" or “App Service")
              which includes the Web site located at{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href="https://www.rupick.app"
              >
                www.rupick.app
              </a>{" "}
              (the “RuPick App Website”) and all RuPick mobile phone
              applications ("Mobile").
            </p>
            <p>
              <b>
                Please read this privacy policy carefully. By accessing or using
                the rupick app service, you agree to be bound by the terms and
                conditions described herein and all terms and conditions
                incorporated by reference. This privacy policy is part of and
                incorporated into rupick app's terms of use ("terms of use"). If
                you do not agree to all of the terms and conditions set forth
                below, you may not use the rupick app service.
              </b>
            </p>
            <p>
              This Privacy Policy applies to information (including personal
              information) collected through the RuPick App Service.
            </p>
            <h3>DISCLAIMER</h3>
            <hr />
            <p>
              <b>
                We do not read your savings account balance. therefore, the
                amount shown as money saved/or earned in the app may not be
                reflective of the money in your account. This information simply
                reflects the money that you have saved/or earned from rupick app
                and does not attempt to accurately state the total balance in
                your savings account.
              </b>
            </p>
            <h3>COLLECTION OF PERSONAL INFORMATION</h3>
            <hr />
            <p>
              We use reasonable measures to maintain the security of your
              Personal Information. However, no company, organization, or online
              community, including the Company or the App, can fully eliminate
              security risks associated with personal information.
            </p>
            <p>
              <b> 1. Personal Data </b>
              <br />
              To avail certain Services on the Portal, Users would be required
              to provide certain information for the registration process
              namely:
            </p>
            <p>
              <ul>
                <li>Name</li>
                <li>Date of Birth (DOB)</li>
                <li>Mobile Number</li>
                <li>Email address</li>
                <li>Username</li>
                <li>Account password</li>
              </ul>
            </p>
            <p>
              Information that you provide to the Company when interacting with
              our customer support team or other team members via phone, email,
              online form, or other correspondence.
            </p>
            <p>
              With your permission (on download or in the app), we may access
              and store your device's contact list to find your friends on the
              RuPick App Service, to invite them, or to share your Content with
              them. We may also collect information about others if you use the
              RuPick App Service to upload, share and/or distribute Content that
              contains information about them, including their name, image, and
              online contact information.
            </p>
            <p>
              Upon receiving a request to delete your account information, we
              will immediately disconnect all connections to your bank accounts,
              along with all personal information that you have provided upon
              the registration for your account. This means that the account
              information that you have provided is no longer retrievable and
              that you will have to create a new account in the event that,
              subsequent to your request to delete the account, you elect to
              again register for the App.
            </p>
            <p>
              <b> 2. Financial Data </b>
              <br />
              Certain financial information (such as information regarding the
              payment instrument/modes used by you to make such payments, which
              may include cardholder name, credit/debit card number (in
              encrypted form) with expiration date, banking details, wallet
              details etc.), all of which is encrypted by the App or third
              parties with which we collaborate, that is necessary to transfer
              funds between your bank accounts.
            </p>

            <p>
              <b> 3. Device Information</b>
              <br />
              We also collect the following information from your device when
              you use the Services, including your mobile device when you use
              our mobile application:
            </p>
            <p>
              <ul>
                <li>The type of device you use</li>
                <li>
                  The IP address of your device, browser type and language
                </li>
                <li>
                  The date and the time during which you accessed the site
                </li>
                <li>
                  The address of the website which you may have used to link to
                  any Website
                </li>
              </ul>
            </p>
            <p>
              <b>4. Application Analytics</b> <br />
              We use mobile analytics software to allow us to better understand
              the functionality of our mobile application. This software may
              record information such as how often you use the application, the
              events that occur within the application, aggregated usage,
              performance data, and where the application was downloaded from.
              We may combine this automatically collected log information with
              other information we collect about you to provide and improve the
              Services.
            </p>
            <p>
              Please note that Anonymous Data are used to gather general
              statistics regarding our customers and visitors to enhance the
              consumer experience on the App. We may also use demographic data
              in a manner that does not identify you specifically but does
              identify certain criteria about our users in general. For example,
              we may inform third parties about the number of registered users,
              the number of unique visitors, and the pages most frequently
              browsed.
            </p>
            <p>
              <b>5. Use of Cookies</b> <br />
              We automatically collect certain information through the use of
              "cookies". Cookies are small data files that are stored on your
              hard drive by a Web site. Among other things, the use of cookies
              helps us to improve the Site and your experience on the Site. We
              use cookies to see which areas and features of the Site are most
              popular, to count the number of computers accessing the Site, to
              personalize your experience and to remember your preferences. If
              your browser is set not to accept cookies or if you reject a
              cookie, you may not be able to access certain features or services
              of the Site.
            </p>
            <p>
              <b>6. Links</b> <br />
              RuPick App also includes links to other websites. Such websites
              are governed by their respective privacy policies, which are
              beyond RuPick App's control. Once the User leaves RuPick App's
              servers (the User can tell where he/she is by checking the URL in
              the location bar on the User's browser), use of any information
              provided by the User is governed by the privacy policy of the
              operator of the site which the User is visiting. That policy may
              differ from RuPick App's own. If the User can't find the privacy
              policy of any of these sites via a link from the site's homepage,
              the User may contact the site directly for more information.
              RuPick App is not responsible for the privacy practices or the
              content of such websites.
            </p>
            <h3>DIGITAL ADVERTISING </h3>
            <hr />
            <p>
              We may partner with ad networks and other ad serving providers
              (“Advertising Providers”) that serve ads on behalf of us and
              others on non-affiliated platforms. Some of those ads may be
              personalized, meaning that they are intended to be relevant to you
              based on information Advertising Providers collect about your use
              of the Site and other sites or apps over time, including
              information about relationships among different browsers and
              devices. This type of advertising is known as interest-based
              advertising.
            </p>
            <p>
              When RuPick App presents information to it's online advertisers --
              to help them understand our audience and confirm the value of
              advertising on the Portal -- it is usually in the form of
              aggregated statistics on traffic to various pages within our site.
              When you register with RuPick App, we contact you from time to
              time about updating your content to provide features which we
              believe may benefit you.
            </p>
            <p>
              Several deceptive emails, websites, blogs etc. claiming to be from
              or associated with RuPick App may or are circulating on the
              Internet. These emails, websites, blogs etc. often include our
              logo, photos, links, content or other information. Some emails,
              websites, blogs etc. call the user to provide login name, password
              etc. or that the user has won a prize/ gift or provide a method to
              commit illegal/ unauthorized act or deed or request detailed
              personal information or a payment of some kind. The sources and
              contents of these emails, websites, blogs etc. and accompanying
              materials are in no way associated with RuPick App. For your own
              protection, we strongly recommend not responding to emails or
              using websites, blogs etc. We may use the information provided by
              you to RuPick App, including your email address or phone number,
              to contact you about the Services availed by you or to inform you
              of our updated Services if any.
            </p>
            <h3>CONFIDENTIALITY AND SECURITY OF PERSONAL INFORMATION</h3>
            <hr />
            <p>
              Except as described in this Privacy Policy, we will not share your
              Personal Information with third parties, unless such disclosure is
              necessary (as determined in our sole discretion) to:
            </p>
            <p>
              <ul>
                <li>The type of device you use</li>
                <li>Comply with a court order or other legal process;</li>
                <li>
                  Protect the rights, property, and/or safety of the Company or
                  any third party; or
                </li>
                <li>Enforce these Terms of Use.</li>
              </ul>
            </p>
            <p>
              We work hard to ensure integrity and security of your information
              (including preventing the loss, misuse, unauthorized access,
              disclosure, alteration and destruction of your information). To
              this end we have incorporated security measures into our system to
              protect you and RuPick App from any data breach. Such measures
              include encryption of your data while in transfer, regular
              revision of security procedures and compliance in the system,
              restricted number of employees under strict confidentiality
              obligations that are authorized to access your information for the
              purposes of legitimate processing and handling, as described
              above. However, we cannot guarantee that unauthorized third
              parties will not be able to defeat our security measures. If you
              use a password on the RuPick App Service, you are responsible for
              keeping it confidential. Do not share it with any other person. If
              you believe your password has been misused or your account
              compromised, please advise us immediately by sending a notice at{" "}
              <a className="link" href="mailto:info@rupick.app">
                info@rupick.app
              </a>
            </p>
            <h3>USE OF PERSONAL INFORMATION</h3>
            <hr />
            <p>We use information collected through the RuPick App Service:</p>
            <p>
              <ul>
                <li>to communicate with you or third parties;</li>
                <li>to notify you about your account activity.</li>
                <li>to process your requests and transactions;</li>
                <li>to provide and improve the RuPick App Service;</li>
                <li>
                  to customize the services and/or products we provide to you;
                </li>
                <li>to assist with our product and service development;</li>
                <li>to perform marketing activities;</li>
                <li>to provide relevant advertising;</li>
                <li>for other purposes related to our business;</li>
                <li>
                  to protect the security of our interests, including our
                  premises, assets, and systems;
                </li>
                <li>to comply with the law; or</li>
                <li>
                  as otherwise disclosed at the time personal information is
                  collected.
                </li>
              </ul>
            </p>
            <h3>SHARING OF PERSONAL INFORMATION</h3>
            <hr />
            <p>
              Information collected through the RuPick App Service may be shared
              with companies and organizations that perform services on our
              behalf (for example, companies that provide data management or
              other support services to us such as data storage and Web hosting
              services). We may share your information with third parties to
              serve you relevant advertising and market our products.
            </p>
            <p>
              We may share your personal information if your transfer to your
              savings account fails, we may provide the recipient bank with
              details of the unsuccessful transfer.
            </p>
            <p>
              With third parties to provide, maintain, and improve the App
              including fraud prevention, identity verification, analytics and
              marketing services, and financial institutions and other partners
              that are part of the payment process. These companies are
              authorized to use your personal information only as necessary to
              provide these services to us.
            </p>
            <p>
              Also, we may disclose the information we collect through the
              RuPick App Service when we, in good faith, believe disclosure is
              appropriate:
            </p>
            <p>
              <ul>
                <li>
                  to comply with applicable law (e.g., in response to a valid
                  court order or subpoena);
                </li>
                <li>
                  to prevent or investigate a possible crime, such as fraud or
                  identity theft;
                </li>
                <li>
                  to enforce the Terms of Use or other agreements that govern
                  your use of the RuPick App Service and/or our services;
                </li>
                <li>
                  pursuant to a subpoena, court order, governmental inquiry, or
                  other legal process or as otherwise required by law, or to
                  protect our rights or the rights of third parties;
                </li>
                <li>
                  with your consent or as otherwise disclosed at the time of
                  data collection or sharing; or
                </li>
                <li>
                  to protect the rights, property, vital interests, or safety of
                  us, our users or others.
                </li>
              </ul>
            </p>
            <p>
              We may disclose your information without giving you prior notice
              if we believe it is necessary to prevent imminent and serious
              bodily harm to a person. Nothing in this privacy policy is
              intended to limit any legal objections or defences you might have
              to efforts by third parties to compel disclosure of your
              information, including legal orders from the government.
            </p>
            <p>
              With a third party if we are involved in a merger, acquisition, or
              sale of all or a portion of our stock or assets. If this occurs,
              you will be notified of any changes to this Privacy Policy, as
              well as any choices you may have regarding your personal
              information.
            </p>
            <p>
              We may share aggregated Anonymous Data, as defined below, with
              third parties. Other than as stated in this Privacy Policy, RuPick
              App does not sell any of your personal information to third
              parties without your consent.
            </p>
            <h3>DATA RETENTION POLICY</h3>
            <hr />
            <p>
              The retention period of collected information depends on the type
              of information and the reasons why we collect it. In case the
              retention of your information is extended for a longer period for
              reasons we define below, we encrypt (anonymize) it to ensure it
              does not directly identify you. Such data is further retained
              until complete deletion becomes possible.
            </p>
            <p>
              Your account information is retained while your account is active,
              unless you request us to delete it or remove your account. In case
              you deactivate/delete your account or request us to delete your
              information, some information may still be retained for a
              reasonable time to enhance your re-activation in case you decide
              to return to our services. We may also retain some information we
              believe in good faith is necessary for legal and regulatory
              compliance, research and development, business matters, and
              improvement of our Services.
            </p>
            <p>
              In cases when you delete your information or request us to delete
              it, some of your information you shared through the Services may
              still be accessible for those with whom you shared it. For
              example, if you shared an image through RuPick App Services, this
              image may still be visible for those with whom you shared, even if
              we delete it from your account.
            </p>
            <h3>NOTIFICATIONS</h3>
            <hr />
            <p>
              We provide you with a daily, weekly and monthly review of your
              progress each morning to encourage, recognize, and reward you for
              your activity. We highly recommend you to provide permission to
              send you these push notifications because they are an integral
              part of the experience.
            </p>
            <h3>LIMITATION OF LIABILITY</h3>
            <hr />
            <p>
              RuPick App confirms that this Privacy Policy is only a description
              of its operation regarding user information. This Policy is not
              intended to and does not create any legal rights in your favour or
              in the favour of any other person. RuPick App reserves the right
              to change this Policy at any time without giving you prior notice.
              The liability of RuPick App shall be limited to removal of
              Sensitive Personal Data from the system of the Websites and
              removal of personally identifiable elements of the Other Personal
              Information. Notwithstanding anything to the contrary contained in
              this Policy and elsewhere, the aggregate liability of RuPick App
              for all claims for damages pursuant to provisions of services on
              the Website, including claims in respect to the violation of this
              Policy, shall be limited to the aggregate maximum amount of
              liability as provided in the{" "}
              <Link className="link" to="/termsofservices">
                Terms of Service.
              </Link>
            </p>
            <p>
              By visiting this Portal, you agree that the laws of the Republic
              of India without regard to its conflict of laws principles, govern
              this Privacy Policy and any dispute arising in respect hereof
              shall be subject to and governed by the dispute resolution process
              set out in the{" "}
              <Link className="link" to="/termsofservices">
                Terms and Conditions.
              </Link>
            </p>
            <h3>UPDATES TO PRIVACY POLICY</h3>
            <hr />
            <p>
              We may make changes to this Privacy Policy at any time. The date
              of the last revision appears at the top of the Policy. We will
              update the effective date of the Privacy Policy when a change is
              made. Any changes made will be made effective immediately upon
              posting. We encourage you to review this Privacy Policy whenever
              you visit the RuPick App Service to understand how your
              information is used.
            </p>
            <h3>CONTACT US</h3>
            <hr />
            <p>
              The security and privacy of your personal information is very
              important to us. If you have any questions or clarifications with
              respect to this Policy or any complaints, comments, concerns or
              feedback, please email{" "}
              <a className="link" href="mailto:info@rupick.app">
                info@rupick.app
              </a>{" "}
              or contact us by normal/physical mail addressed to:
            </p>
            <p>
              Attn: RuPick Pvt. Ltd., <br />
              10/06, Bajrang Chawl, <br />
              Rawalpada, Ganeshnagar, Dahisar (East)
              <br />
              Mumbai 400068, Maharashtra, India.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
