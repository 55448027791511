import React from "react";
import "../../styles/companyinfo/whereweare.css";
import UsableButton from "../button/UsableButton";
import Map from "../map/Map";
import mail from "../../logo/mail.gif";
import calender from "../../logo/calender.gif";
import phone from "../../logo/phone.gif";

function WhereWeAre({ samePage = false }) {
  return (
    <div>
      <div className="where-we-are">
        <div className="sub-heading">
          <h2>WHERE WE ARE?</h2>
          <div className="main-info">
            <p>
              Our office is located in Mumbai, India, <b>RuPick</b> is a
              subsidiary brand of <b>RuPick</b> Pvt. Ltd. a leading Internet and
              social media company that operates multiple users and customer
              engagement brands.
            </p>
          </div>
        </div>
        <div className="main-info-inner">
          <div className="main-map">
            <Map />
          </div>
          <div className="customer-support">
            <h2>Customer Support</h2>
            <div className="customer-support-info">
              <ul>
                <li>
                  <div className="info">
                    <div className="icon">
                      <img src={calender} alt="calender image" />
                    </div>
                    <div className="text">
                      <p>Monday to Friday - 9:00AM to 6:00PM</p>
                      <p>Saturday & Sunday - 10:00AM to 4:00PM</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info">
                    <div className="icon">
                      <img src={mail} alt="mail image" />
                    </div>
                    <div className="text">
                      <p>
                        <a href="mailto:info@rupick.app">info@rupick.app</a>
                      </p>
                      <p>
                        <a href="mailto:support@rupick.in">support@rupick.in</a>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info">
                    <div className="icon">
                      <img src={phone} alt="phone image" />
                    </div>
                    <div className="text">
                      <p>
                        <a href="tel:9619228682">9619228682</a>
                      </p>
                      <p>
                        <a href="tel:8291662688">8291662688</a>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {!samePage && (
          <div className="btn">
            <UsableButton
              color="light"
              hreflink="/contact"
              button={true}
              text="Contact Us"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default WhereWeAre;
