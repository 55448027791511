import React, { useContext, useEffect } from "react";
import "../commonheader/CommonHeader";
import CommonHeader from "../commonheader/CommonHeader";
import * as Yup from "yup";
import { password } from "../../config/ValidationSchema";
import { Formik } from "formik";
import "../../styles/login/login.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getAuth, confirmPasswordReset, applyActionCode } from "firebase/auth";
import ComponentContext from "../context/ComponentContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function UserAction(props) {
  const { setLinks } = useContext(ComponentContext);

  const location = useLocation();
  const navigate = useNavigate();
  const code = new URLSearchParams(location.search).get("oobCode");
  const actionMode = new URLSearchParams(location.search).get("mode");
  const signupValidation = Yup.object().shape({
    password: password,
  });
  useEffect(() => {
    setLinks({
      cominfo: true,
      howitworks: true,
      advertise: true,
      help: true,
      contact: true,
    });
    if (actionMode === "verifyEmail") {
      applyActionCode(getAuth(), code)
        .then(() => {
          console.log("email verified");
          toast.success("Your email has been verified.");
          navigate("/", { replace: true });
        })
        .catch((err) => {
          toast.error("Your email verifcation has failed. Try again.");
        });
    }
  }, []);
  return (
    <div>
      <CommonHeader link={true} />
      {actionMode === "verifyEmail" ? (
        <div className="login">
          <div className="wrapper">
            <h2>Email Verification</h2>
            <p style={{ textAlign: "center", marginTop: "1rem" }}>
              We will notify you within few seconds about your email
              verification.
            </p>
          </div>
        </div>
      ) : (
        <div className="login">
          <div className="wrapper">
            <h2>New Password</h2>
            <div className="login-form">
              <Formik
                validationSchema={signupValidation}
                initialValues={{ password: "" }}
                onSubmit={(values) => {
                  console.log(values.password);
                  confirmPasswordReset(getAuth(), code, values.password)
                    .then((res) => {
                      console.log(res);
                      toast.success("Your password has been reset.");
                      navigate("/login", { replace: "true" });
                    })
                    .catch((err) => {
                      toast.success(
                        "Your password didn't get reset. Try again."
                      );
                    });
                }}
              >
                {({ errors, touched, handleChange, handleSubmit, values }) => (
                  <div className="main-form">
                    <div className="form-wrapper">
                      <input
                        type="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange("password")}
                        style={{
                          borderColor: touched.password
                            ? errors.password
                              ? "red"
                              : "green"
                            : "lightgray",
                        }}
                      />
                      {errors.password && touched.password && (
                        <p className="error-msg">{errors.password}</p>
                      )}
                    </div>
                    <div className="form-wrapper">
                      <input
                        type="password"
                        placeholder="Confirm Paasowrd"
                        value={values.password}
                        onChange={handleChange("password")}
                        style={{
                          borderColor: touched.password
                            ? errors.password
                              ? "red"
                              : "green"
                            : "lightgray",
                        }}
                        id="password2"
                      />
                      <button
                        id="eye2"
                        style={{
                          outline: "none",
                          border: "none",
                          position: "absolute",
                          top: 17,
                          right: 15,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const pass = document.getElementById("password2");
                          const hide = document.getElementById("hide2");
                          const show = document.getElementById("show2");
                          if (pass.type == "password") {
                            pass.setAttribute("type", "text");
                            hide.setAttribute("style", "display:block");
                            show.setAttribute("style", "display:none");
                          } else {
                            pass.setAttribute("type", "password");
                            show.setAttribute("style", "display:block");
                            hide.setAttribute("style", "display:none");
                          }
                        }}
                      >
                        <i
                          className="far fa-eye-slash"
                          id="hide2"
                          style={{ display: "none" }}
                        ></i>
                        <i
                          className="far fa-eye"
                          id="show2"
                          style={{ display: "block" }}
                        ></i>
                      </button>
                    </div>

                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="submitBtn"
                    >
                      Submit
                    </button>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserAction;
