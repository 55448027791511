import React, { useContext, useEffect } from "react";
import CommonHeader from "../commonheader/CommonHeader";
import Features from "./Features";
import Works from "./Works";
import ComponentContext from "../context/ComponentContext";
import GoToTop from "../GoToTop";
import Footer from "../footer/Footer";

function HowItWorks() {
  const { links, setLinks } = useContext(ComponentContext);
  useEffect(() => {
    setLinks({
      cominfo: true,
      howitworks: false,
      advertise: true,
      help: true,
      contact: true,
    });
  }, []);

  return (
    <div>
      <CommonHeader link="true" />
      <Features />
      <Works />
      <GoToTop />
      <Footer />
    </div>
  );
}

export default HowItWorks;
