import { useState } from "react";
import ComponentContext from "./ComponentContext";

function ComponentState(props) {
  const [links, setLinks] = useState({
    cominfo: true,
    howitworks: true,
    advertise: true,
    help: true,
    contact: true,
  });

  return (
    <ComponentContext.Provider
      value={{
        links,
        setLinks,
      }}
    >
      {props.children}
    </ComponentContext.Provider>
  );
}

export default ComponentState;
