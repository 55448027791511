import React, { useContext, useState } from "react";
import logoimg from "../../logo/logo-transparent-1.png";
import logoimgtext from "../../logo/logo-letter-transparent.png";
import UsableButton from "../button/UsableButton";
import { Link } from "react-router-dom";
import ComponentContext from "../context/ComponentContext";
import "../../styles/commonheader/commonheader.css";

function CommonHeader({ button, link }) {
  const {
    links: { cominfo, howitworks, advertise, help, contact },
  } = useContext(ComponentContext);
  const [menubtn, setMenuBtn] = useState({ active: false, class: "" });
  const [menubtnres, setMenuBtnRes] = useState({ active: false, class: "" });
  // const [navmenu, setNavMenu] = useState({ active: false, class: "" });
  const btnChange = () => {
    if (!menubtn.active) {
      setMenuBtn({ active: true, class: "active" });
    } else {
      setMenuBtn({ active: false, class: "" });
    }
  };
  const btnChangeRes = () => {
    if (!menubtnres.active) {
      setMenuBtnRes({ active: true, class: "active" });
    } else {
      setMenuBtnRes({ active: false, class: "" });
    }
  };
  return (
    <header className="header">
      <div>
        <Link to="/" className="main-logo">
          <img
            className="logo-img"
            id="logo"
            src={logoimg}
            alt="rupick logo without text"
          />
          <img
            className="logo-img-text"
            src={logoimgtext}
            alt="rupick logo with text"
          />
        </Link>
      </div>
      <div className="downloadApp1">
        {button && (
          <div>
            <UsableButton
              text="Login/Signup"
              icon="fa fa-sign-in"
              button={true}
              hreflink="/login"
            />
          </div>
        )}
      </div>
      <div className="downloadApp2">
        {button && (
          <div>
            <UsableButton
              text="Login"
              icon="fa fa-sign-in"
              button={true}
              hreflink="/login"
            />
          </div>
        )}
      </div>
      <div className="downloadApp3">
        {button && (
          <div>
            <UsableButton
              text=""
              icon="fa fa-sign-in"
              button={true}
              hreflink="/login"
            />
          </div>
        )}
      </div>
      {link && (
        <div className="nav-menu">
          <div id="nav-links" className={`nav-links ${menubtn.class}`}>
            {cominfo && (
              <Link className="links" to="/companyinfo">
                Company Info
              </Link>
            )}
            {howitworks && (
              <Link className="links" to="/howitworks">
                How It works
              </Link>
            )}
            {advertise && (
              <Link className="links" to="/advertise">
                Advertise
              </Link>
            )}
            {help && (
              <Link className="links" to="/helpfaq">
                Help(FAQ)
              </Link>
            )}
            {contact && (
              <Link className="links" to="/contact">
                Contact
              </Link>
            )}
          </div>
          <div
            id="menu-btn"
            className={`nav-btn ${menubtn.class}`}
            onClick={btnChange}
          >
            <div className="btn"></div>
          </div>
          <div
            id="nav-links"
            className={`nav-links-responsive ${menubtnres.class}`}
          >
            {cominfo && (
              <Link className="links" to="/companyinfo">
                Company Info
              </Link>
            )}
            {howitworks && (
              <Link className="links" to="/howitworks">
                How It works
              </Link>
            )}
            {advertise && (
              <Link className="links" to="/advertise">
                Advertise
              </Link>
            )}
            {help && (
              <Link className="links" to="/helpfaq">
                Help(FAQ)
              </Link>
            )}
            {contact && (
              <Link className="links" to="/contact">
                Contact
              </Link>
            )}
            <Link
              className={`downloadlink dark`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
              to={"/comingsoon"}
            >
              Download App
            </Link>
          </div>

          <div
            id="menu-btn"
            className={`nav-btn-responsive ${menubtnres.class}`}
            onClick={btnChangeRes}
          >
            <div className="btn"></div>
          </div>
        </div>
      )}
    </header>
  );
}

CommonHeader.defaultProps = {
  button: false,
  link: false,
};

export default CommonHeader;
