import React, { useContext, useEffect } from "react";
import CommonHeader from "../commonheader/CommonHeader";
import ComponentContext from "../context/ComponentContext";
import GoToTop from "../GoToTop";
import Contactus from "./Contactus";
import WhereWeAre from "../companypage/WhereWeAre";
import Footer from "../footer/Footer";

function Contact() {
  const { links, setLinks } = useContext(ComponentContext);
  useEffect(() => {
    setLinks({
      cominfo: true,
      howitworks: true,
      advertise: true,
      help: true,
      contact: false,
    });
  }, []);

  return (
    <div>
      <CommonHeader link="true" />
      <Contactus />
      <WhereWeAre samePage={true} />
      <GoToTop />
      <Footer />
    </div>
  );
}

export default Contact;
