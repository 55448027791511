import "../../styles/home/home.css";
import React from "react";
import Friend from "./Friend";
import NavLinks from "./NavLinks";
import Rupee from "./Rupee";
import Clients from "./Clients";
import GoToTop from "../GoToTop";
import Footer from "../footer/Footer";

// home page component which contains different section components..
function Home() {
  return (
    <>
      <div className="home-section">
        <NavLinks />
        <Friend />
        <Rupee />
        {/* <Clients /> */}
        <GoToTop />
        <Footer />
      </div>
    </>
  );
}

export default Home;
