import React from "react";
import CountUp from "react-countup";

function Counter({ start, end, icon = false }) {
  return (
    <div>
      <CountUp
        start={start}
        end={end}
        delay={0}
        duration={3}
        separator=","
        useEasing
      >
        {({ countUpRef }) => (
          <div>
            {icon && "₹"}
            <span ref={countUpRef} />
          </div>
        )}
      </CountUp>
    </div>
  );
}
export default Counter;
