import React from "react";
import "../../styles/howitworks/works.css";
import completetask from "../../logo/howitworks/complete-task-max-min.png";
import playcontest from "../../logo/howitworks/play-contest-max-min.png";
import signup from "../../logo/howitworks/signup-max-min.png";
import watchvideos from "../../logo/howitworks/watch-videos-max-min.png";

function Works() {
  return (
    <div className="work-section">
      <div className="sub-heading">
        <h2>How Does It Work?</h2>
        <div className="sub-heading-inner">
          <h3>GETTING STARTED SIMPLE, FAST AND INTUITIVE. </h3>
        </div>
      </div>
      <section className="work">
        <div className="work-inner">
          <div className="work-inner-box work-box">
            <div className="ih-item square colored effect10 top_to_bottom">
              <div className="square-inner">
                <div className="img">
                  <img src={signup} alt="signup image" />
                </div>
                <div className="info">
                  <p>Create a new account and login to get started.</p>
                </div>
              </div>
            </div>
            <div className="title">
              <h2>Sign Up</h2>
            </div>
          </div>
          <div className="work-inner-box work-box">
            <div className="ih-item square colored effect10 top_to_bottom">
              <div className="square-inner">
                <div className="img">
                  <img src={playcontest} alt="playcontest image" />
                </div>
                <div className="info">
                  <p>
                    Multiply your Coins by participating into different
                    contests.
                  </p>
                </div>
              </div>
            </div>
            <div className="title">
              <h2>Play Contests</h2>
            </div>
          </div>
          <div className="work-inner-box work-box">
            <div className="ih-item square colored effect10 top_to_bottom">
              <div className="square-inner">
                <div className="img">
                  <img src={completetask} alt="completetask image" />
                </div>
                <div className="info">
                  <p>Complete tasks to level up and earn reward points.</p>
                </div>
              </div>
            </div>
            <div className="title">
              <h2>Complete Tasks</h2>
            </div>
          </div>
          <div className="work-inner-box work-box">
            <div className="ih-item square colored effect10 top_to_bottom">
              <div className="square-inner">
                <div className="img">
                  <img src={watchvideos} alt="watchvideos image" />
                </div>
                <div className="info">
                  <p>Watch videos and earn Coins.</p>
                </div>
              </div>
            </div>
            <div className="title">
              <h2>Watch Videos</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Works;
