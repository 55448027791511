import React, { useContext, useEffect } from "react";
import CommonHeader from "../commonheader/CommonHeader";
import ComponentContext from "../context/ComponentContext";
import GoToTop from "../GoToTop";
import FAQ from "./FAQ";
import Footer from "../footer/Footer";

function HelpFaq() {
  const { links, setLinks } = useContext(ComponentContext);
  useEffect(() => {
    setLinks({
      cominfo: true,
      howitworks: true,
      advertise: true,
      help: false,
      contact: true,
    });
  }, []);

  return (
    <div>
      <CommonHeader link="true" />
      <FAQ />
      <GoToTop />
      <Footer />
    </div>
  );
}

export default HelpFaq;
