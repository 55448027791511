import React from "react";
import { Link } from "react-router-dom";

import "../../styles/home/link-card.css";

function LinkCard({ src, title, link }) {
  return (
    <>
      <Link className="link-card" to={`${link}`}>
        <svg className="link-img" data-src={src}></svg>
        <h3 className="link-title">{title}</h3>
      </Link>
    </>
  );
}

export default LinkCard;
