import * as Yup from "yup";

export const name = Yup.string()
  .min(3, "Name must be at least 3 characters")
  .max(50, "Name can have a maximum of 40 characters")
  .required("Name is required");

export const username = Yup.string()
  .trim()
  .required("Username is required")
  .min(3, "Username must be at least 3 characters")
  .max(20, "Username can have a maximum of 20 characters")
  .matches(
    /^[a-zA-Z0-9_]+$/,
    "Username can only contain alphanumeric characters and underscores"
  );

export const password = Yup.string()
  .trim()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters")
  .max(20, "Password can have a maximum of 20 characters")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
    "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
  );

export const email = Yup.string()
  .required("Email is required")
  .email("Invalid Email");
