import React from "react";
import "../../styles/companyinfo/whatwedo.css";
import currentlyActive from "../../icons/CurrentlyActive.svg";
import newSignUp from "../../icons/NewSignUp.svg";
import contestsParticipants from "../../icons/contestParticipants.svg";
import { Link } from "react-router-dom";

function WhatWeDo() {
  return (
    <div>
      <div className="main-section">
        <div className="what-we-do">
          <div className="sub-heading">
            <h2>WHAT WE DO?</h2>
            <div className="main-info">
              <p>
                <b>RuPick</b> is the "First" ever INDIA's most popular social
                media app that gives you free rewards or credit points for
                performing specified tasks or participating in contests or
                complying with the requirements on your uploaded Content. Redeem
                rewards or credit points for cash into PayTM wallet or bank
                Account. We have already paid out over 7,00,000 Rs. in cash.
                Join for free today.
              </p>
            </div>
          </div>
          <div>
            <div>
              <div className="friend-counter-section">
                <div className="counter-section-inner">
                  <svg className="link-img" data-src={currentlyActive}></svg>
                  <h2>
                    Currently <br className="break" /> Active
                  </h2>
                  <h3>100000</h3>
                </div>
                <div className="counter-section-inner">
                  <svg className="link-img" data-src={newSignUp}></svg>
                  <h2>
                    New <br className="break" /> SignUp
                  </h2>
                  <h3>100000</h3>
                </div>
                <div className="counter-section-inner">
                  <svg
                    className="link-img"
                    data-src={contestsParticipants}
                  ></svg>
                  <h2>
                    All <br className="break" /> Contestants
                  </h2>
                  <h3>600000</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="bonus">
            <Link to="/login" className="bonus-inner">
              Instant Cash Bonus <br />
              ₹25
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
