import React, { useContext, useEffect } from "react";
import CommonHeader from "../commonheader/CommonHeader";
import ComponentContext from "../context/ComponentContext";
import GoToTop from "../GoToTop";
import AdvertisePage from "./AdvertisePage";
import Results from "./Results";
import Waysofadvertise from "./Waysofadvertise";
import Footer from "../footer/Footer";

function Advertise() {
  const { links, setLinks } = useContext(ComponentContext);
  useEffect(() => {
    setLinks({
      cominfo: true,
      howitworks: true,
      advertise: false,
      help: true,
      contact: true,
    });
  }, []);

  return (
    <div>
      <CommonHeader link="true" />
      <AdvertisePage />
      <Waysofadvertise />
      <Results />
      <GoToTop />
      <Footer />
    </div>
  );
}

export default Advertise;
