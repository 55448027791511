import "../../styles/home/navlinks.css";

import React from "react";
import LinkCard from "./LinkCard";
import companyInfo from "../../icons/CompanyInfo.svg";
import howitworks from "../../icons/HowItWorks.svg";
import advertise from "../../icons/Advertise.svg";
import helpfaq from "../../icons/Help(FAQ).svg";
import contact from "../../icons/contact.svg";
import CommonHeader from "../commonheader/CommonHeader";
import Fade from "react-reveal/Fade";

function NavLinks() {
  return (
    <div className="parent-link">
      <CommonHeader button="true" />
      <div className="navlinks">
        <section className="link-section">
          <div className="main-heading">
            <h1>TIME IS MONEY</h1>
            <h2>"We Pay What You Deserve"</h2>
          </div>
        </section>
        <main className="nav-features">
          <div>
            <Fade left>
              <div className="nav-feature-1">
                <LinkCard
                  src={companyInfo}
                  title="Company Info"
                  link="/companyinfo"
                />
              </div>
            </Fade>
          </div>
          <div>
            <Fade right>
              <div className="nav-feature-2">
                <LinkCard
                  src={howitworks}
                  title="How It Works?"
                  link="/howitworks"
                />
              </div>
            </Fade>
          </div>

          <div>
            <Fade left>
              <div className="nav-feature-3">
                <LinkCard src={advertise} title="Advertise" link="/advertise" />
              </div>
            </Fade>
          </div>

          <div>
            <Fade right>
              <div className="nav-feature-4">
                <LinkCard src={helpfaq} title="Help(FAQ)" link="/helpfaq" />
              </div>
            </Fade>
          </div>
          <div>
            <Fade left>
              <div>
                <div className="nav-feature-5">
                  <LinkCard src={contact} title="Contact" link="/contact" />
                </div>
              </div>
            </Fade>
          </div>
        </main>
      </div>
    </div>
  );
}

export default NavLinks;
