import React from "react";
import UsableButton from "../button/UsableButton";
import "../../styles/advertise/advertisepage.css";

import * as LottiePlayer from "@lottiefiles/lottie-player";

function Advertisepage() {
  return (
    <div>
      <div className="advertise">
        <div className="advertise-section">
          <div className="advertise-section-inner">
            <div>
              <div className="advertise-section-info">
                <h3>REACH MILLIONS OF ENTHUSIASTS</h3>
                <p>
                  With over 10,00,000 registered users and millions of page
                  views each month,<b> RuPick </b>offers some of the the most
                  effective advertising in the industry. Get in touch with our
                  marketing team to learn how we can help you boost your
                  business, promote your products, build your brand or increase
                  your engagement.
                </p>
                <div className="btn">
                  <UsableButton
                    color="light"
                    text="Get Started"
                    button={true}
                    hreflink="/advertisewithus"
                    padding="1rem 2rem"
                  />
                </div>
                <p className="footer">
                  Powered by the <b> RuPick </b> platform.
                </p>
              </div>
            </div>
            <div className="advertise-video">
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_une35akn.json"
                mode="bounce"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advertisepage;
