import React, { useEffect, useState, useContext } from "react";
import "../../components/commonheader/CommonHeader";
import CommonHeader from "../../components/commonheader/CommonHeader";
import * as Yup from "yup";
import { email, password, username } from "../../config/ValidationSchema";
import { Formik } from "formik";
import "../../styles/login/login.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  setDoc,
  doc,
  getDocs,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import ComponentContext from "../context/ComponentContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import googleLogo from "../../icons/google.svg";

export default function Login() {
  const provider = new GoogleAuthProvider();

  const navigate = useNavigate();
  const auth = getAuth();
  auth.useDeviceLanguage();

  const { setLinks } = useContext(ComponentContext);

  useEffect(() => {
    const loginBtn = document.querySelector("#login");
    const signupBtn = document.querySelector("#signup");
    const signupLink = document.querySelector("#signuplink");
    const loginLink = document.querySelector("#loginlink");

    signupLink.onclick = () => {
      signupBtn.click();
      return false;
    };
    loginLink.onclick = () => {
      loginBtn.click();
      return false;
    };
    setLinks({
      cominfo: true,
      howitworks: true,
      advertise: true,
      help: true,
      contact: true,
    });
  }, []);

  const googleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        console.log("data", credential, "token", token, "user", user);
        setDoc(
          doc(getFirestore(), "users", result.user.uid),
          {
            username: result.user.email.split("@")[0],
            email: result.user.email,
          },
          { merge: true }
        ).then(() => {
          toast.success("Signin Successful.");
          navigate("/", { replace: true });
        });

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        toast.error("Some error has occured.");
        // ...
      });
  };

  const doLogin = async (values) => {
    await signInWithEmailAndPassword(getAuth(), values.email, values.password)
      .then(async (user) => {
        toast.success("Signin Successful.");
        navigate("/", { replace: true });
        console.log(auth.currentUser.emailVerified);
      })
      .catch((err) => {
        console.log(err);
        if (err.message.includes("user-not-found")) {
          toast.error("Couldn't find your email.");
        } else {
          toast.error("Password is incorrect.");
        }
      });
  };
  const doSignup = async (values) => {
    const firestore = getFirestore();
    const q1 = query(
      collection(getFirestore(), "users"),
      where("email", "==", values.email)
    );
    const q2 = query(
      collection(getFirestore(), "users"),
      where("username", "==", values.username)
    );
    const querySnapshot1 = await getDocs(q1);
    const querySnapshot2 = await getDocs(q2);
    if (querySnapshot1.size !== 0 || querySnapshot2.size !== 0) {
      if (querySnapshot1.size !== 0 && querySnapshot2.size !== 0) {
        toast.error("User already exists with this email and username.");
      } else if (querySnapshot1.size !== 0) {
        toast.error("User with email already exists.");
      } else if (querySnapshot2.size !== 0) {
        toast.error("Username already exists.");
      }
    } else {
      try {
        createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        ).then((res) => {
          setDoc(doc(firestore, "users", res.user.uid), {
            username: values.username,
            email: values.email,
          }).then(() => {
            toast.success("Verification link has been sent to email.");
            console.log(res.user.emailVerified);
            sendEmailVerification(res.user);
          });

          navigate("/", { replace: true });
          console.log("success login");
        });
      } catch (err) {
        console.log("error", err);
        toast.error("Some error has occured.");
      }
    }
  };

  const loginValidation = Yup.object().shape({
    email: email,
    password: password,
  });
  const signupValidation = Yup.object().shape({
    username,
    username,
    email: email,
    password: password,
  });

  const [loginBool, setLoginBool] = useState(true);

  return (
    <div>
      <CommonHeader link={true} />
      <div className="login">
        <div className="wrapper">
          <h2>{loginBool ? "Login Form" : "Signup Form"}</h2>
          <div class="slide-controls">
            <input
              type="radio"
              name="slide"
              id="login"
              onClick={() => {
                setLoginBool(true);
              }}
            />
            <input
              type="radio"
              name="slide"
              id="signup"
              onClick={() => {
                setLoginBool(false);
              }}
            />
            <label for="login" class="slide login">
              Login
            </label>
            <label for="signup" class="slide signup">
              Signup
            </label>
            <div class="slider-tab"></div>
          </div>
          <div
            className="login-form"
            style={{
              display: !loginBool ? "none" : "block",
            }}
          >
            <Formik
              validationSchema={loginValidation}
              initialValues={{ email: "", password: "" }}
              onSubmit={(values) => {
                doLogin(values);
              }}
            >
              {({ errors, touched, handleChange, handleSubmit, values }) => (
                <div className="main-form">
                  <div className="form-wrapper">
                    <input
                      type="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange("email")}
                      style={{
                        borderColor: touched.email
                          ? errors.email
                            ? "red"
                            : "green"
                          : "lightgray",
                      }}
                    />
                    {errors.email && touched.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-wrapper">
                    <input
                      type="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange("password")}
                      style={{
                        borderColor: touched.password
                          ? errors.password
                            ? "red"
                            : "green"
                          : "lightgray",
                      }}
                      id="password"
                    />
                    <button
                      id="eye"
                      style={{
                        outline: "none",
                        border: "none",
                        position: "absolute",
                        top: 17,
                        right: 15,
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        const pass = document.getElementById("password");
                        const hide = document.getElementById("hide");
                        const show = document.getElementById("show");
                        if (pass.type == "password") {
                          pass.setAttribute("type", "text");
                          hide.setAttribute("style", "display:block");
                          show.setAttribute("style", "display:none");
                        } else {
                          pass.setAttribute("type", "password");
                          show.setAttribute("style", "display:block");
                          hide.setAttribute("style", "display:none");
                        }
                      }}
                    >
                      <i
                        className="far fa-eye-slash"
                        id="hide"
                        style={{ display: "none" }}
                      ></i>
                      <i
                        className="far fa-eye"
                        id="show"
                        style={{ display: "block" }}
                      ></i>
                    </button>

                    {errors.password && touched.password && (
                      <p className="error-msg">{errors.password}</p>
                    )}
                  </div>

                  <div>
                    <Link className="forgot-link" to={"/forgotpassword"}>
                      Forget password?
                    </Link>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="submitBtn"
                  >
                    Login
                  </button>
                  <div>
                    <p style={{ marginTop: "1rem", textAlign: "center" }}>
                      Create an account?{" "}
                      <Link
                        id="signuplink"
                        className="forgot-link"
                        to={"/login"}
                        onClick={() => {
                          setLoginBool(false);
                        }}
                      >
                        Signup Now
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              )}
            </Formik>
          </div>
          <div
            className="signup-form"
            style={{
              display: loginBool ? "none" : "block",
            }}
          >
            <Formik
              validationSchema={signupValidation}
              initialValues={{ username: "", email: "", password: "" }}
              onSubmit={(values) => {
                doSignup(values);
              }}
            >
              {({ errors, touched, handleChange, handleSubmit, values }) => (
                <div className="main-form">
                  <div className="form-wrapper">
                    <input
                      type="text"
                      placeholder="Username"
                      value={values.username}
                      onChange={handleChange("username")}
                      style={{
                        borderColor: touched.username
                          ? errors.username
                            ? "red"
                            : "green"
                          : "lightgray",
                      }}
                    />
                    {errors.username && touched.username && (
                      <p className="error-msg">{errors.username}</p>
                    )}
                  </div>
                  <div className="form-wrapper">
                    <input
                      type="text"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange("email")}
                      style={{
                        borderColor: touched.email
                          ? errors.email
                            ? "red"
                            : "green"
                          : "lightgray",
                      }}
                    />
                    {errors.email && touched.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-wrapper">
                    <input
                      type="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange("password")}
                      style={{
                        borderColor: touched.password
                          ? errors.password
                            ? "red"
                            : "green"
                          : "lightgray",
                      }}
                    />
                    {errors.password && touched.password && (
                      <p className="error-msg">{errors.password}</p>
                    )}
                  </div>
                  <div className="form-wrapper">
                    <input
                      type="password"
                      placeholder="Confirm Paasowrd"
                      value={values.password}
                      onChange={handleChange("password")}
                      style={{
                        borderColor: touched.password
                          ? errors.password
                            ? "red"
                            : "green"
                          : "lightgray",
                      }}
                      id="password2"
                    />
                    <button
                      id="eye2"
                      style={{
                        outline: "none",
                        border: "none",
                        position: "absolute",
                        top: 17,
                        right: 15,
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        const pass = document.getElementById("password2");
                        const hide = document.getElementById("hide2");
                        const show = document.getElementById("show2");
                        if (pass.type == "password") {
                          pass.setAttribute("type", "text");
                          hide.setAttribute("style", "display:block");
                          show.setAttribute("style", "display:none");
                        } else {
                          pass.setAttribute("type", "password");
                          show.setAttribute("style", "display:block");
                          hide.setAttribute("style", "display:none");
                        }
                      }}
                    >
                      <i
                        className="far fa-eye-slash"
                        id="hide2"
                        style={{ display: "none" }}
                      ></i>
                      <i
                        className="far fa-eye"
                        id="show2"
                        style={{ display: "block" }}
                      ></i>
                    </button>
                  </div>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="submitBtn"
                  >
                    Sign Up
                  </button>
                  <div>
                    <p style={{ marginTop: "1rem", textAlign: "center" }}>
                      Already have an account?{" "}
                      <Link
                        id="loginlink"
                        className="forgot-link"
                        to={"/login"}
                        onClick={() => {
                          setLoginBool(true);
                        }}
                      >
                        Login
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              )}
            </Formik>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              margin: "1rem 0",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "lightgray",
                marginRight: "10px",
              }}
            ></div>
            <p>OR</p>
            <div
              style={{
                height: "2px",
                backgroundColor: "lightgray",
                width: "100%",
                marginLeft: "10px",
              }}
            ></div>
          </div>
          <svg
            style={{ cursor: "pointer" }}
            data-src={googleLogo}
            onClick={googleLogin}
          ></svg>
        </div>
      </div>
    </div>
  );
}
