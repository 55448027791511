import React, { useContext, useEffect } from "react";
import CommonHeader from "../commonheader/CommonHeader";
import ComponentContext from "../context/ComponentContext";
import GoToTop from "../GoToTop";
import Privacy from "./Privacy";
import Footer from "../footer/Footer";

function PrivacyPolicy() {
  const { setLinks } = useContext(ComponentContext);
  useEffect(() => {
    setLinks({
      cominfo: true,
      howitworks: true,
      advertise: true,
      help: true,
      contact: true,
    });
  }, []);

  return (
    <div>
      <CommonHeader link="true" />
      <Privacy />
      <GoToTop />
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
