import React from "react";
import "../../styles/contact/contactform.css";

function ContactForm() {
  return (
    <div className="contact-form">
      <div className="contact-card">
        <div className="sub-heading">
          <h2>CONTACT US</h2>
        </div>
        <div className="contact-card-inner">
          <div className="video">
            <lottie-player
              src="https://assets1.lottiefiles.com/packages/lf20_u25cckyh.json"
              mode="normal"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div className="contact-card-info" id="contactForm">
            <form
              action="mailto://info@rupick.app"
              method="post"
              encType="text/plain"
              className="contact-form"
            >
              <div className="input-group">
                <input
                  className="input-control"
                  type="text"
                  name="FullName"
                  id="FullName"
                  placeholder="Full Name"
                  required
                />
              </div>
              <div className="input-group">
                <input
                  className="input-control"
                  type="text"
                  name="Company"
                  id="Company"
                  placeholder="Company Name"
                  required
                />
              </div>
              <div className="input-group">
                <input
                  className="input-control"
                  type="email"
                  name="Email"
                  id="Email"
                  placeholder="Email Address"
                  required
                />
              </div>
              <div className="input-group">
                <input
                  className="input-control"
                  type="text"
                  name="Number"
                  id="Number"
                  placeholder="Phone Number"
                  required
                />
              </div>
              <div className="input-group">
                <textarea
                  className="input-control textarea"
                  placeholder="Message"
                  name="Message"
                  id="Message"
                  cols="70"
                ></textarea>
              </div>
              <div className="send-btn">
                <button className="submit-btn" type="submit">
                  Submit &nbsp;
                  <i className="far fa-arrow-alt-circle-right"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
