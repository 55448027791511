import React from "react";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import "../../styles/advertise/results.css";
import UsableButton from "../button/UsableButton";

function Results() {
  return (
    <div>
      <div className="results-section">
        <div className="sub-heading">
          <h2>RESULTS</h2>
          <p>
            With over 10,00,000 registered users and millions of page views each
            month, <b>RuPick</b> offers some of the the most effective
            advertising in the industry.
          </p>
        </div>

        <div className="results-section-inner">
          <div className="video">
            <lottie-player
              src="https://assets1.lottiefiles.com/packages/lf20_0eousc0t.json"
              mode="normal"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div className="info">
            <h3>7.5M+ Page Views</h3>
            <p>
              <b>RuPick</b> serves over 10 million page views a month and over
              100 Million ad impressions a month.
            </p>
            <h3>Daily Ad Click</h3>
            <p>
              Our cost effective Paid to Click (PTC) platform delivers over
              200,000 clicks a day to our advertisers. It's easy: Purchase an
              ad. Your ad is approved within 8 - 12 hours. Users click your ad
              and receive a small reward. You receive targeted traffic
              guaranteed to view your content.
            </p>
          </div>
        </div>
        <div className="results-section-inner">
          <div className="info second">
            <h3>1.5M+ Registered Users</h3>
            <p>
              With over 670,000 registered users and over 150,000 monthly active
              users, we have the volume to meet your campaign goals. We reward
              our users for completing actions on your website or platform,
              reading your emails or viewing your content. You get new
              customers, our users get more Coins and we take a small cut. It's
              a win-win-win relationship. We have thousands of new users joining
              our rewards platform every day. That translates to thousands of
              new potential customers for your business, on a daily basis.
            </p>
          </div>
          <div className="video">
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_x9svjl26/data.json"
              mode="normal"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
        <div className="results-section-inner">
          <div className="video third-video">
            <lottie-player
              src="https://assets10.lottiefiles.com/packages/lf20_ait757on.json"
              mode="bounce"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div className="info third">
            <h3>Creative & Powrful Advertising</h3>
            <p>
              Our unique rewards platform allows us to offer creative and highly
              effective advertising solutions. We offer sponsored promotions,
              banner ads, media ads, earn from in-app ads and much more!
            </p>
            <h3>Daily Leads</h3>
            <p>
              We are experts at generating leads across a wide range of devices
              and platforms. We can help you increase your user base for your
              product or service. You only pay for results.
            </p>
            <div className="btn">
              <UsableButton
                color="light"
                text="Reach Us"
                button={true}
                hreflink="/advertisewithus"
                padding="1rem 2rem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Results;
