import React from "react";
import "../../styles/footer/footer.css";
import UsableButton from "../button/UsableButton";
import { Link } from "react-router-dom";
import logoimgtext from "../../logo/logo-letter-transparent-2.png";
import logoimg from "../../logo/logo-transparent.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-section">
        <div className="logo">
          <Link to="/">
            <img src={logoimgtext} alt="rupick main-logo" />
          </Link>
        </div>
        <div className="logo2">
          <Link to="/">
            <img
              src={logoimg}
              style={{ width: "100%", height: "100%" }}
              alt="rupick main-logo"
            />
          </Link>
        </div>
        <div className="download-btn db1">
          <UsableButton
            color="light"
            text="Download App"
            icon="far fa-arrow-alt-circle-down"
            button={true}
            hreflink="/comingsoon"
          />
        </div>
        <div className="download-btn db2">
          <UsableButton
            color="light"
            text="Download"
            icon="far fa-arrow-alt-circle-down"
            button={true}
            hreflink="/comingsoon"
          />
        </div>

        <div className="footer-section-icons">
          <div className="social-icon">
            <Link to="/comingsoon">
              <i className="fab fa-twitter"></i>
            </Link>
            <Link to="/comingsoon">
              <i className="fab fa-facebook"></i>
            </Link>
            <a
              href="https://instagram.com/rupick.app?igshid=MmJiY2I4NDBkZg=="
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a href="mailto:info@rupick.app" target="_blank" rel="noreferrer">
              <i className="fas fa-envelope"></i>
            </a>
            <Link to="/comingsoon">
              <i className="fab fa-linkedin"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="border"></div>
      <div className="copyright-text">
        <div className="text">
          <p>
            © 2021 - {new Date().getFullYear()} RuPick - All rights reserved.
          </p>
        </div>
        <div className="company-links">
          {/* <Link className="link" to="/termsofservices">
            Terms Of Services
          </Link> */}
          <Link className="link" to="/privacypolicy">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
