import React, { useContext, useEffect } from "react";
import CommonHeader from "../commonheader/CommonHeader";
import ComponentContext from "../context/ComponentContext";
import "../../styles/advertise/advertisewithus.css";
import ContactForm from "../contactpage/ContactForm";
import GoToTop from "../GoToTop";

function Advertisewithus() {
  const { setLinks } = useContext(ComponentContext);
  useEffect(() => {
    setLinks({
      cominfo: true,
      howitworks: true,
      advertise: true,
      help: true,
      contact: true,
    });
  }, []);

  return (
    <div>
      <CommonHeader link="true" />
      <div className="advertise-with-us">
        <div className="sub-heading">
          <h2>ADVERTISE WITH US</h2>
        </div>
        <div className="advertise-with-us-inner">
          <div className="inner">
            <h3>Business Development</h3>
            <p className="email-link">
              <a href="mailto://info@rupick.app">info@rupick.app</a>
            </p>

            <h3>Pricing</h3>
            <p>We have advertising options for both big and small budgets.</p>
            <h3>What To Expect</h3>
            <p>
              One of our business managers will get back to you within 24 - 48
              hours (usually faster). We will discuss your budget and goals then
              design a campaign to meet those goals.Your account manager will
              help you launch your campaign & continue to work with you to
              ensure your campaign is a success.
            </p>
          </div>
          <div className="video">
            <lottie-player
              src="https://assets10.lottiefiles.com/packages/lf20_IG1Hp6.json"
              mode="bounce"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
        <ContactForm />
      </div>
      <GoToTop />
    </div>
  );
}

export default Advertisewithus;
